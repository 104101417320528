{
  "name": "one-access",
  "version": "0.28.0",
  "license": "UNLICENSED",
  "private": true,
  "engines": {
    "node": "^20.11.1 || 20.15.1"
  },
  "os": [
    "win32",
    "darwin",
    "linux"
  ],
  "cpu": [
    "x64",
    "arm64"
  ],
  "scripts": {
    "ng": "nx",
    "postinstall": "node ./decorate-angular-cli.js",
    "start": "nx serve",
    "build": "nx build",
    "test": "nx test",
    "docs:json": "compodoc -p ./tsconfig.base.json -e json -d .",
    "storybook": "npm run docs:json && storybook dev -p 6006",
    "build-storybook": "npm run docs:json && storybook build",
    "run:launcher-server": "node dist/apps/launcher/server/main.js",
    "run:labs-server": "node dist/apps/labs/lab-server/main.js",
    "run:homecare-server": "node dist/apps/homecare/viewer-server/main.js",
    "run:meds-server": "node dist/apps/meds/viewer-server/main.js"
  },
  "overrides": {
    "ansi-regex": "^5.0.1",
    "glob-parent": "^6.0.2",
    "ini": "^3.0.0",
    "minimist": "^1.2.6",
    "node-forge": "^1.3.1",
    "semver": "7.5.2",
    "terser": "5.14.2",
    "trim": "^1.0.1",
    "trim-newlines": "3.0.1",
    "mkdirp": "^1.0.4"
  },
  "dependencies": {
    "@angular/animations": "18.1.2",
    "@angular/cdk": "18.1.1",
    "@angular/common": "18.1.2",
    "@angular/compiler": "18.1.2",
    "@angular/core": "18.1.2",
    "@angular/forms": "18.1.2",
    "@angular/platform-browser": "18.1.2",
    "@angular/platform-browser-dynamic": "18.1.2",
    "@angular/platform-server": "18.1.2",
    "@angular/router": "18.1.2",
    "@lapo/extractcms": "^1.0.4",
    "@ng-select/ng-select": "^13.5.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@storybook/core-server": "^8.2.6",
    "ag-grid-angular": "^28.2.1",
    "ag-grid-community": "^28.2.1",
    "ag-grid-enterprise": "^28.2.1",
    "ajv": "^8.17.1",
    "ajv-formats": "^2.1.1",
    "axios": "1.7.4",
    "bootstrap": "5.2.2",
    "connect-redis": "^6.1.3",
    "cookie-parser": "^1.4.6",
    "cors": "^2.8.5",
    "dayjs": "^1.11.12",
    "express": "4.19.2",
    "express-flash": "0.0.2",
    "express-session": "^1.18.0",
    "express-useragent": "^1.0.15",
    "express-validator": "^6.15.0",
    "handlebars": "4.7.7",
    "helmet": "^5.1.1",
    "jose": "^4.15.9",
    "jsonwebtoken": "^9.0.2",
    "lru-cache": "10.1.0",
    "ng-multiselect-dropdown": "^1.0.0",
    "ngx-bootstrap": "^18.0.2",
    "ngx-mask": "^17.1.8",
    "node-fetch": "2.6.7",
    "node-forge": "^1.3.1",
    "node-localstorage": "^2.2.1",
    "openid-client": "^5.6.5",
    "passport": "^0.7.0",
    "properties-reader": "^2.3.0",
    "redis": "^4.7.0",
    "rxjs": "7.8.1",
    "sanitize": "^2.1.2",
    "tslib": "^2.4.1",
    "uid-safe": "2.1.5",
    "uuid": "8.3.2",
    "xml-js": "^1.6.11",
    "zone.js": "0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/architect": "0.1801.2",
    "@angular-devkit/build-angular": "18.1.2",
    "@angular-devkit/core": "18.1.2",
    "@angular-devkit/schematics": "18.1.2",
    "@angular-eslint/eslint-plugin": "18.0.1",
    "@angular-eslint/eslint-plugin-template": "18.0.1",
    "@angular-eslint/template-parser": "18.0.1",
    "@angular/cli": "18.1.2",
    "@angular/compiler-cli": "18.1.2",
    "@angular/elements": "18.1.2",
    "@angular/language-service": "18.1.2",
    "@angular/localize": "18.1.2",
    "@babel/core": "^7.20.2",
    "@chromatic-com/storybook": "^1.6.1",
    "@compodoc/compodoc": "^1.1.25",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@nrwl/angular": "19.5.2",
    "@nrwl/express": "19.5.2",
    "@nrwl/jest": "19.5.2",
    "@nrwl/node": "19.5.2",
    "@nx/cypress": "19.5.2",
    "@nx/devkit": "19.5.2",
    "@nx/esbuild": "19.5.2",
    "@nx/eslint": "19.5.2",
    "@nx/eslint-plugin": "19.5.2",
    "@nx/express": "19.5.2",
    "@nx/jest": "19.5.2",
    "@nx/js": "19.5.2",
    "@nx/node": "19.5.2",
    "@nx/plugin": "19.5.2",
    "@nx/storybook": "19.5.2",
    "@nx/webpack": "19.5.2",
    "@nx/workspace": "19.5.2",
    "@pmmmwh/react-refresh-webpack-plugin": "^0.5.15",
    "@schematics/angular": "18.1.2",
    "@storybook/addon-a11y": "^8.2.6",
    "@storybook/addon-actions": "^8.2.6",
    "@storybook/addon-essentials": "^8.2.6",
    "@storybook/addon-interactions": "^8.2.6",
    "@storybook/addon-links": "^8.2.6",
    "@storybook/addon-storyshots": "^7.6.17",
    "@storybook/angular": "^8.2.6",
    "@storybook/core-server": "^8.2.6",
    "@storybook/test": "^8.2.6",
    "@svgr/webpack": "^8.1.0",
    "@swc-node/register": "1.9.2",
    "@swc/core": "1.5.7",
    "@types/bootstrap": "^5.2.10",
    "@types/connect-redis": "^0.0.18",
    "@types/cors": "^2.8.17",
    "@types/express": "4.17.21",
    "@types/cookie-parser": "^1.4.7",
    "@types/express-flash": "0.0.2",
    "@types/express-session": "^1.18.0",
    "@types/express-useragent": "^1.0.5",
    "@types/fhir": "0.0.34",
    "@types/jest": "29.4.4",
    "@types/jsonwebtoken": "^8.5.9",
    "@types/node": "18.16.9",
    "@types/node-localstorage": "^1.3.3",
    "@types/properties-reader": "^2.1.3",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "7.16.0",
    "@typescript-eslint/parser": "7.16.0",
    "@webcomponents/custom-elements": "^1.6.0",
    "@types/uid-safe": "^2.1.5",
    "babel-loader": "^8.3.0",
    "concurrently": "^7.6.0",
    "copy-webpack-plugin": "^9.1.0",
    "cypress": "13.13.0",
    "cypress-axe": "^1.5.0",
    "dotenv": "^16.4.5",
    "esbuild": "^0.19.12",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-cypress": "2.13.4",
    "eslint-plugin-storybook": "^0.8.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-environment-node": "^29.7.0",
    "jest-preset-angular": "14.1.1",
    "jsonc-eslint-parser": "^2.4.0",
    "ng-mocks": "^14.13.0",
    "ngx-translate-testing": "^6.1.0",
    "nx": "19.5.2",
    "prettier": "2.6.2",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-refresh": "^0.10.0",
    "ts-jest": "29.1.1",
    "ts-node": "10.9.1",
    "typescript": "5.5.4",
    "url-loader": "^4.1.1",
    "webpack": "^5.93.0",
    "webpack-bundle-analyzer": "^4.10.2",
    "webpack-merge": "^5.8.0",
    "webpack-node-externals": "^3.0.0"
  },
  "optionalDependencies": {
    "@nx/nx-linux-x64-gnu": "19.5.2",
    "@nx/nx-win32-x64-msvc": "19.5.2"
  },
  "browser": {
    "fs": false,
    "path": false,
    "os": false,
    "crypto": false,
    "stream": false,
    "http": false,
    "tls": false,
    "zlib": false,
    "https": false,
    "net": false
  }
}
